import React, { FC } from 'react'
import { useDropzone } from 'react-dropzone'

import { PictureUpload, FileSizeLimit } from './style'
import { useTranslation } from 'react-i18next'

type DropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void
  fileName: string | null
  multiple?: boolean
  locale?: string
}

const Dropzone: FC<DropzoneProps> = ({ multiple = true, locale = 'en', ...props }: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: props.onDrop, multiple })
  const { t } = useTranslation()

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PictureUpload {...getRootProps()} className={props.fileName ? 'active' : ''}>
      <input {...getInputProps()} />
      {props.fileName ? (
        <p>{props.fileName}</p>
      ) : isDragActive ? (
        <p>{t('dropzone:files', { lng: locale })}</p>
      ) : (
        <p>
          {t('dropzone:images', { lng: locale })}
          <br />
          <FileSizeLimit>
            {t('The file size limit is 5 MB and the max dimensions are 2.000 x 2.000 pixels', { lng: locale })}
          </FileSizeLimit>
        </p>
      )}
    </PictureUpload>
  )
}

export default Dropzone
