import React, { FunctionComponent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { faTimes } from '@fortawesome/free-solid-svg-icons'

import Button from 'shared/ButtonModal'

import { ActivityCategorySelect } from './components/ActivityCategorySelect/ActivityCategorySelect'
import { IdleSelectState } from './components/IdleSelectState/IdleSelectState'
import { MenuSelect } from './components/MenuSelect/MenuSelect'
import { IProps } from './IProps'
import { ActivityContainer, RemoveIcon, RemoveIconContainer, SelectionContainer, SelectionName } from './style'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const ActivitySelect: FunctionComponent<IProps> = ({
  activitySelectObject,
  setActivitySelectObject,
  activityList,
  menuList,
}) => {
  const { t } = useTranslation()
  const faRemoveIcon = faTimes as IconProp

  const resetSelection = () => {
    setActivitySelectObject({
      type: 'idle',
      activeSelection: null,
      selectionDetails: null,
    })
    return
  }

  const removeLastSelection = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (activitySelectObject.activeSelection && activitySelectObject.activeSelection.length === 0) {
      setActivitySelectObject({
        type: 'idle',
        activeSelection: null,
        selectionDetails: null,
      })
      return
    }
    setActivitySelectObject({
      type: activitySelectObject.type,
      activeSelection: activitySelectObject.activeSelection ? activitySelectObject.activeSelection.slice(0, -1) : null,
      selectionDetails: null,
    })
    return
  }

  switch (activitySelectObject.type) {
    case 'idle':
      return (
        <SelectionContainer>
          <IdleSelectState setActivitySelectObject={setActivitySelectObject} />
        </SelectionContainer>
      )
    case 'menu':
      return (
        <SelectionContainer>
          <MenuSelect
            menuList={menuList}
            activitySelectObject={activitySelectObject}
            setActivitySelectObject={setActivitySelectObject}
          />
          <Button customStyle={{ marginTop: '25px' }} handleClick={removeLastSelection}>
            {t('Back')}
          </Button>
        </SelectionContainer>
      )
    case 'activity':
      return (
        <SelectionContainer>
          <ActivityCategorySelect
            activityList={activityList}
            activitySelectObject={activitySelectObject}
            setActivitySelectObject={setActivitySelectObject}
          />
          <Button customStyle={{ marginTop: '25px' }} handleClick={(e) => removeLastSelection(e)}>
            {t('Back')}
          </Button>
        </SelectionContainer>
      )
    case 'selected':
      return (
        <ActivityContainer>
          <>
            {activitySelectObject.selectionDetails
              ? activitySelectObject.selectionDetails.map((selection) => (
                <div>
                  <SelectionName key={selection.uuid}>{selection.selectionDetails.name}</SelectionName>
                  <SelectionName key={selection.uuid}>{selection.selectionDetails.price}</SelectionName>
                  </div>
              ))
              : null}
          </>
          <RemoveIconContainer onClick={resetSelection}>
            <RemoveIcon icon={faRemoveIcon} />
          </RemoveIconContainer>
        </ActivityContainer>
      )
    default:
      return (
        <SelectionContainer>
          <IdleSelectState setActivitySelectObject={setActivitySelectObject} />
        </SelectionContainer>
      )
  }
}
