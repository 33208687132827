import React, { FunctionComponent, useState } from 'react'
import { StyledBurger, StyledBurgerMenu, StyledBurgerStripes } from 'components/Navigation/NavigationBurger/style'
import { SortableContainer } from 'react-sortable-hoc'
import NavigationList from 'components/Navigation/NavigationList'
import { IProps } from './IProps'
import { Wrapper as ItemWrapper } from 'components/Navigation/NavigationItem/style'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from '../../../helpers/hooks/useWindowDimensions/useWindowDimensions'
import { NavigationLogo, NavigationLogoWrapper } from 'components/Navigation/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NavigationBurger: FunctionComponent<IProps> = ({
  currentPage,
  localPages,
  onSortEnd,
  locale,
  screenClass,
  setLogoOpen,
  logoSrc,
}) => {
  const { t } = useTranslation()
  const { windowWidth } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(false)

  const SortableNavigationList = SortableContainer(NavigationList)

  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'
  return (
    <div>
      <StyledBurger open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <StyledBurgerStripes open={isOpen} />
        <StyledBurgerStripes open={isOpen} />
        <StyledBurgerStripes open={isOpen} />
      </StyledBurger>
      {isOpen && (
        <StyledBurgerMenu open={isOpen}>
          <NavigationLogoWrapper>
            <NavigationLogo onClick={() => setLogoOpen(true)} src={logoSrc + '&w=100&h=80'} alt="navigation-logo" />
          </NavigationLogoWrapper>
          <SortableNavigationList
            /* @ts-expect-error */
            items={localPages}
            currentPage={currentPage}
            onSortEnd={onSortEnd}
            axis={'x'}
            distance={10}
            open={isOpen}
          />

          <ItemWrapper width={windowWidth} themeTitle={themeTitle}>
            <Link className={screenClass} to="/page/new">
              <FontAwesomeIcon icon={'circle-plus'} /> {t('page:add_page', { lng: locale })}
            </Link>
          </ItemWrapper>
        </StyledBurgerMenu>
      )}
    </div>
  )
}
