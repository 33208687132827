import React, { FC, useEffect, useState } from 'react'
import { useScreenClass } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import Logo from './Logo'
import { IHeader } from '../../types/sections'
import { Section, HeaderStyled, Title, Button, VideoSection, VideoBanner } from './style'
import { Api } from '../../constants'

import { ITheme } from '@optios-frontend-website/website-public/src/types/theme'

type Props = {
  handleHeaderClick?: (event: React.MouseEvent<HTMLElement>) => void
  handleLogoClick?: (event: React.MouseEvent<HTMLElement>) => void
  logoSrc: string
  headerSrc: string
  isVideo?: boolean
  data: IHeader
  bookingId: string | undefined
  theme: ITheme
}

const Header: FC<Props> = (props: Props) => {
  const { is_online_booking, phone } = props.data

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    }
  }, [])

  const onlineBookingUrl = Api.BOOKING_URL + props.bookingId

  const screenClass = useScreenClass()
  const { t } = useTranslation()

  let SectionComponent: typeof Section | typeof VideoSection = Section

  if (props.isVideo) {
    SectionComponent = VideoSection
  }

  return (
    <SectionComponent
      className={screenClass}
      onClick={props.handleHeaderClick}
      src={props.headerSrc}
      windowWidth={windowWidth}
    >
      {props.isVideo ? <VideoBanner src={props.headerSrc} loop autoPlay muted /> : null}
      {props.theme.title === 'Classic' && (
        <>
          <Logo handleClick={props.handleLogoClick} src={props.logoSrc} data={props.data} />
          <HeaderStyled className={screenClass} onClick={props.handleHeaderClick}>
            <Title
              dangerouslySetInnerHTML={{
                __html: t(is_online_booking ? 'header:book_online' : 'header:book_phone'),
              }}
            />
            <Button
              href={is_online_booking && props.bookingId ? onlineBookingUrl : `tel:${phone}`}
              target="_blank"
              rel="noreferrer"
            >
              {is_online_booking ? t('header:reserve') : phone}
            </Button>
          </HeaderStyled>
        </>
      )}
    </SectionComponent>
  )
}
export default Header
