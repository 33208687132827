import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { MainNav, Seperator, NavA, NavIcon } from './style'

import MenuItem from './MenuItem/MenuItem'

const SideMenu: FunctionComponent = () => {
  const [t] = useTranslation()

  const menus = [
    { icon: 'home', title: t('menu:home'), path: 'https://client.optios.net', classNames: ['home', 'selected'] },
    {
      icon: 'calendar',
      title: t('menu:calendar'),
      path: 'https://client.optios.net/calendar',
      classNames: ['calendar'],
    },
    {
      icon: 'customers',
      title: t('menu:customers'),
      path: 'https://client.optios.net/customers',
      classNames: ['customers'],
    },
    {
      icon: 'register',
      title: t('menu:register'),
      path: 'https://client.optios.net/register',
      classNames: ['register'],
    },
    {
      icon: 'products',
      title: t('menu:products'),
      path: 'https://client.optios.net/products',
      classNames: ['products'],
    },
    {
      icon: 'punchclock',
      title: t('menu:human_resources'),
      path: 'https://client.optios.net/hr',
      classNames: ['punchclock'],
    },
    {
      icon: 'reports',
      title: t('menu:reports'),
      path: 'https://client.optios.net/report',
      classNames: ['reports'],
    },
    {
      icon: 'marketing',
      title: t('menu:marketing'),
      path: 'https://client.optios.net/marketing',
      classNames: ['marketing'],
    },
  ]

  const menuElements = []
  for (let i = 0; i < menus.length; i++) {
    menuElements.push(<MenuItem key={'menu' + menus[i].icon} {...menus[i]} />)
  }

  return (
    <MainNav>
      {menuElements}
      <Seperator />
      <NavA href={'https://help.optios.net/en/'} target="_blank" className="minor support" title={t('menu:support')}>
        <NavIcon className="icon icon-on icon-support" />
        <NavIcon className="icon icon-off icon-support-neg" />
      </NavA>
      <NavA
        href={'https://client.optios.net/settings/account'}
        target="_blank"
        className="minor"
        title={t('menu:settings')}
      >
        <NavIcon className="icon icon-on icon-settings" />
        <NavIcon className="icon icon-off icon-settings-neg" />
      </NavA>
      <NavA href={'https://client.optios.net/logout'} target="_blank" className="minor" title={t('menu:logout')}>
        <NavIcon className="icon icon-on icon-logout" />
        <NavIcon className="icon icon-off icon-logout" />
      </NavA>
    </MainNav>
  )
}
export default SideMenu
