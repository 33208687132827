import styled from '@optios-frontend-website/shared-ui/src/theme'

type WrapperProps = {
  pagesLength?: boolean
  themeTitle: string
  width: number
}

export const NavigationText = styled.p<{ themeTitle: string }>`
  line-height: ${({ themeTitle }) => (themeTitle === 'Classic' ? '3.5' : '4')};
  font-weight: ${({ themeTitle }) => (themeTitle === 'Classic' ? '100' : '')};
`

export const SubnavArrow = styled.a`
  padding: 0 0 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 900px) {
    padding-left: 20px;
  }
`

export const ArrowSpan = styled.span`
  width: 4px;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  right: 6px;

  span {
    top: 0.5rem;
    position: absolute;
    width: 9px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.feedbackTitle};
    display: inline-block;
    transition: all 0.2s ease;
    border-radius: 25px;

    @media (min-width: 900px) {
      height: 1px;
    }

    &:first-of-type {
      left: 0;
      transform: rotate(-45deg);
    }

    &:last-of-type {
      right: 0;
      transform: rotate(45deg);
    }
  }
`

export const SubNavWrapper = styled.div`
  display: none;
  width: 100%;
  margin-left: 16px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 900px) {
    width: auto;
    z-index: 2;
    padding: 0 16px;
    position: absolute;
    top: 44px;
    left: 4px;
    margin-left: 0;
    background-color: #ffffff;
    box-sizing: content-box;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.25);

    &:before {
      content: '';
      position: absolute;
      left: 8px;
      display: block;
      width: 0;
      height: 0;
      border-color: #ffffff;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom-width: 7px;
      border-bottom-style: solid;
      top: -7px;
    }
  }

  @media (min-width: 992px) {
    top: 56px;
    left: 8px;
  }

  @media (min-width: 1601px) {
    top: 50px;
  }

  > a {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.feedbackTitle} !important;

    &:hover {
      color: ${({ theme }) => theme.colors.feedbackTitle} !important;

      @media (min-width: 900px) {
        color: #ece1da !important;
      }
    }
  }
`

export const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  width: ${({ width }) => (width < 900 ? '100%' : 'auto')};
  padding: ${({ width, pagesLength }) =>
    width > 1600 && pagesLength ? '0 0.5em' : width > 1600 ? '0 1.5em' : '0 0.5em'};
  line-height: 3;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: ${({ width }) => (width > 1600 ? '21px 10px' : '21px 0')};
  color: ${({ theme }) => theme.colors.feedbackTitle};
  position: relative;

  @media (max-width: 899px) {
    flex-wrap: wrap;

    > a {
      flex-grow: 1;
    }
  }

  @media (min-width: 900px) {
    height: 54px;
    justify-content: center;

    &.active {
      color: ${({ theme }) => theme.colors.secondary};
      background: ${({ themeTitle }) => (themeTitle === 'Classic' ? '#e3e3e3' : 'none')};
    }
  }

  .subnav-wrapper {
    display: flex;
  }

  @media (min-width: 900px) {
    .subnav-wrapper {
      display: none;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
      transition: color 0.3s ease 0s;
      border-bottom: ${({ theme }) => theme.title.fontDecorationBottom};

      .subnav-wrapper {
        display: flex;
      }

      .arrow {
        span:first-of-type {
          transform: rotate(45deg);
        }

        span:last-of-type {
          transform: rotate(-45deg);
        }
      }
    }
  }

  a {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ pagesLength, theme }) => (pagesLength ? '0.65em' : theme.sizes.xsmall)};
    text-transform: ${({ theme }) => theme.title.fontDecoration};
    text-decoration: none;
    color: inherit;
  }

  &.md,
  &.sm,
  &.xs {
    width: 100%;
  }
`
