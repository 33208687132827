import styled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.primary};
`
export const MenuContainer = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 32px 16px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export const LastItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 220px;
`

export const BookButtonWrapper = styled.div<{ width: number; offSide: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 22px 0;
  height: 50px;
`

export const BookButton = styled.a<{ width: number; pagesLength: boolean }>`
  font-size: ${({ theme }) => theme.sizes.xsmall};
  font-family: ${({ theme }) => theme.fonts.primary};
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 18px;
  color: ${({ theme }) => theme.colors.feedbackTitle};
  cursor: pointer;
  padding: ${({ pagesLength }) => (pagesLength ? '10px 12px' : '12px 18px')};
  line-height: ${({ width }) => (width < 900 ? '2.2em' : '1.8em')};
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  vertical-align: middle;
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const BookButtonIcon = styled(FontAwesomeIcon)<{
  pages?: boolean
  window?: boolean
}>`
  margin-right: ${({ pages, window }) => (pages && window ? '0' : '14px')};
  font-size: ${({ theme, pages, window }) => (pages && window ? '1em' : theme.sizes.xsmall)};
`

export const ShopifyButtonWrapper = styled.div<{ width: number; offSide: boolean }>`
  display: flex;
  justify-content: center;
  margin: 22px 0;
  height: 50px;
`

export const ShopifyButton = styled.a<{ width: number }>`
  font-size: ${({ theme }) => theme.sizes.xsmall};
  font-family: ${({ theme }) => theme.fonts.primary};
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 18px;
  color: ${({ theme }) => theme.colors.feedbackTitle};
  cursor: pointer;
  padding: 0 12px;
  line-height: ${({ width }) => (width < 900 ? '4em' : '3.2em')};
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  vertical-align: middle;
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const ShopifyIcon = styled(FontAwesomeIcon)<{
  pages?: boolean
  window?: boolean
}>`
  margin-right: ${({ pages, window }) => (pages && window ? '0' : '14px')};
  font-size: ${({ theme, pages, window }) => (pages && window ? '1em' : theme.sizes.xsmall)};
`

export const Container2 = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  right: 0;
  @media (max-width: 767px) {
    width: auto;
  }
`
