import { IConfig, IConfigSection, IData, IStorageConfig } from 'store/config/types'

const initialSectionState: IConfigSection = {
  configurable: false,
  slug: '',
  title: '',
}

const initialStorageConfigState: IStorageConfig = {
  asset_types: [],
  upload_url: '',
  url: '',
  shopify_url: '',
  woocommerce_url: '',
}

const initialDataState: IData = {
  textandimages: null,
  feedback: null,
  gallery: null,
  logo: null,
  banner: null,
  booking: null,
  menu: null,
  activities: null,
}

export const initialState: IConfig = {
  languages: {},
  locale: 'fr',
  is_shopify_enabled: false,
  is_woo_commerce_enabled: false,
  sections: {
    booking: initialSectionState,
    contact: initialSectionState,
    feedbacks: initialSectionState,
    footer: initialSectionState,
    gallery: initialSectionState,
    header: initialSectionState,
    map: initialSectionState,
    pricing: initialSectionState,
    textandimages: initialSectionState,
  },
  storageConfig: initialStorageConfigState,
  facebook_pages: [],
  data: initialDataState,
}
