import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Api, SectionNames } from '@optios-frontend-website/shared-ui/src/constants'

import SectionControl from 'shared/SectionControl'
import { Container as ContainerGrid } from 'react-grid-system'
import {
  BookButton,
  BookButtonIcon,
  BookButtonWrapper,
  Container,
  Container2,
  LastItemsContainer,
  MenuContainer,
  ShopifyButton,
  ShopifyButtonWrapper,
  ShopifyIcon,
} from './style'

import Header from 'components/Header'
import TextImage from 'components/TextImage'
import Gallery from 'components/Gallery'
import Languages from 'components/Languages'
import Map from 'components/Map'
import { PricingCrud } from 'components/PricingCrud/PricingCrud'
import Feedback from 'components/Feedback'
import Footer from 'components/Footer'
import InlineContainer from 'shared/InlineContainer'
import FixedButtonContainer from 'shared/FixedButtonContainer'

import {
  IHeader,
  IInline,
  IMap,
  IPage,
  ISection,
  ITextAndImage,
  PageSectionTypes,
  IFooter,
  IGallery,
  IFixedButtonSingle,
  IActivityCategories,
  IPageItem,
  IFeedback,
  IBooking,
} from 'store/page/types'

import { IExtraInformation } from './interface/IExtraInformation'
import { IHeaderInformation } from './interface/IHeaderInformation'
import { addFixedButtonSection, addInlineSection, updatePageSection } from 'store/page/actions'

import { updateCurrentLanguage } from 'store/website/actions'
import { addConfigBySlug } from 'store/config/actions'
import { IConfig, IFacebookPage } from 'store/config/types'
import { IWebsite } from 'store/website/types'
import { ApplicationState, RootState } from 'store'
import { Navigation } from 'components/Navigation'
import FixedButtonSectionControl from 'shared/FixedButtonSectionControl'
import FacebookChat from 'components/FacebookChat'
import { ITheme } from 'store/theme/types'
import { useWindowDimensions } from '../../helpers/hooks/useWindowDimensions/useWindowDimensions'

interface Props {
  page: IPage
  config: IConfig
  website: IWebsite
  theme: ITheme
}

type AllProps = Props & typeof mapDispatch

const Update: FC<AllProps> = (props: AllProps) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const { windowWidth } = useWindowDimensions()

  const [localPages, setPages] = useState<IPageItem[]>([])
  const { storage, configs } = useSelector((state: ApplicationState) => ({
    configs: state.config,
    storage: state.config.storageConfig,
  }))

  const [isOnlineBooking, setIsOnlineBooking] = useState<boolean>(false)
  const [bookingId, setBookingId] = useState<string | undefined>('')
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('')
  const [data, setData] = useState<IHeader>()
  const [logoIsOpen, setLogoOpen] = useState(false)
  const establishmentId = useSelector<RootState, string>((state) => state.website.establishmentId)

  const [fontColor, setFontColor] = useState<string>('#000000')
  const [sliderValue, setSliderValue] = useState<number>(0)
  const [imageUrl, setImageUrl] = useState<string>('')

  const [isShopifyEnabled, setIsShopifyEnabled] = useState<boolean | null>(false)
  const [shopifyUrl, setShopifyUrl] = useState<string | null>('')
  const [isWooCommerceEnabled, setIsWooCommerceEnabled] = useState<boolean | null>(false)
  const [wooCommerceUrl, setWooCommerceUrl] = useState<string | null>('')

  const onlineBookingUrl = Api.BOOKING_URL + establishmentId

  const {
    config,
    page,
    website,
    updateCurrentLanguage,
    updatePageSection,
    addInlineSection,
    addFixedButtonSection,
    addConfigBySlug,
    theme,
  } = props

  const updateSection = (name: string, section: PageSectionTypes, position: number, col?: number) => {
    updatePageSection(name, section, position, col)
  }

  const getInformation = useCallback((): IExtraInformation => {
    const booking = page.currentPage?.sections.find((item) => item.name === SectionNames.BOOKING)

    const information: IExtraInformation = {
      isOnlineBooking: false,
      bookingId: (booking?.data as IBooking)?.url,
      phone: undefined,
    }

    page.currentPage?.sections?.forEach((section) => {
      if (section.name === SectionNames.HEADER) {
        const data = section.data as IHeader
        information.isOnlineBooking = data.is_online_booking
        information.phone = data.phone
      }
    })

    return information
  }, [page])

  const getHeaderInformation = useCallback((): IHeaderInformation => {
    const header = page.currentPage?.sections.find((item) => item.name === SectionNames.HEADER)

    return {
      data: header?.data as IHeader,
    }
  }, [page])

  useEffect(() => {
    setIsShopifyEnabled(configs.is_shopify_enabled)
    setShopifyUrl(storage.shopify_url)
    setIsWooCommerceEnabled(configs.is_woo_commerce_enabled)
    setWooCommerceUrl(storage.woocommerce_api_url)
  }, [configs, storage])

  useEffect(() => {
    setIsOnlineBooking(getInformation().isOnlineBooking)
    setBookingId(getInformation().bookingId)
    setPhoneNumber(getInformation().phone)
    setData(getHeaderInformation().data)
  }, [getInformation, getHeaderInformation])

  const renderSection = (section: ISection, config: IConfig, position: number) => {
    const getFixedButtonContainer = (section: ISection, position: number) => {
      if ('fixedbutton' === section.name) {
        return (
          <FixedButtonContainer
            website={website}
            addFixedButtonSection={addFixedButtonSection}
            position={position}
            parentSectionName={section.name}
            parentPosition={position}
            data={section.data as IFixedButtonSingle[]}
            updateSection={updateSection}
            configs={config.data.textandimages}
          />
        )
      }
    }

    const { name, data } = section
    switch (name) {
      case SectionNames.HEADER:
        const booking = page.currentPage?.sections.find((item) => item.name === SectionNames.BOOKING)
        return (
          <Fragment>
            <Container2>
              {page.currentPage?.sections?.map((section, position) => {
                if (position === 1 && 'fixedbutton' !== section.name) {
                  return (
                    <Fragment key={position + section.name}>
                      {getFixedButtonContainer(section, position)}
                      <FixedButtonSectionControl
                        isPageManagement={true}
                        position={position - 1}
                        isDelete={false}
                        isAdd={true}
                      >
                        <ContainerGrid key={position + section.name} />
                      </FixedButtonSectionControl>
                    </Fragment>
                  )
                }
                switch (section.name) {
                  case SectionNames.FIXEDBUTTON:
                    return (
                      <>
                        {getFixedButtonContainer(section, position)}
                        <FixedButtonSectionControl
                          isPageManagement={true}
                          position={position}
                          isDelete={true}
                          isAdd={false}
                        >
                          <ContainerGrid key={position + section.name} />
                        </FixedButtonSectionControl>
                      </>
                    )
                }
              })}
            </Container2>
            <Header
              position={position}
              data={data as IHeader}
              logo={config.data.logo}
              banner={config.data.banner}
              prefixUrl={config?.storageConfig.url}
              bookingId={(booking?.data as IBooking)?.url}
              addConfigBySlug={addConfigBySlug}
              updateSection={updateSection}
              theme={theme}
              logoIsOpen={logoIsOpen}
              setLogoOpen={setLogoOpen}
            />
          </Fragment>
        )
      case SectionNames.FEEDBACK:
        return <Feedback position={position} data={data as IFeedback[]} />
      case SectionNames.TEXTANDIMAGES:
        return (
          <Fragment>
            <TextImage
              configs={config.data.textandimages}
              config={config?.storageConfig}
              position={position}
              data={data as ITextAndImage[]}
              updateSection={updateSection}
            />
          </Fragment>
        )
      case SectionNames.GALLERY:
        return (
          <Gallery
            updateSection={updateSection}
            position={position}
            config={config?.storageConfig}
            data={data as IGallery[]}
          />
        )
      case SectionNames.FOOTER:
        return (
          <Footer
            position={position}
            data={data as IFooter}
            updateSection={updateSection}
            pages={page.list}
            locale={page.currentPage?.locale}
          />
        )
      case SectionNames.MAP:
        return <Map position={position} data={data as IMap} updateSection={updateSection} />
      case SectionNames.PRICING:
        return (
          <PricingCrud
            bookingId={bookingId}
            isOnlineBooking={isOnlineBooking}
            phoneNumber={phoneNumber}
            position={position}
            activityCategories={data as IActivityCategories}
            updateSection={updateSection}
          />
        )
      case SectionNames.CONTACT:
      case SectionNames.HTML:
      case SectionNames.PHOTO:
      case SectionNames.BUTTON:
      case SectionNames.EMPLOYEE:
      case SectionNames.FORM: {
        return (
          <InlineContainer
            data={data as IInline}
            website={website}
            addInlineSection={addInlineSection}
            position={position}
            updateSection={updateSection}
            config={config}
            fontColor={fontColor}
            setFontColor={setFontColor}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            prefixUrl={config?.storageConfig.url}
          />
        )
      }

      case SectionNames.INLINE:
        return (
          <>
            <InlineContainer
              website={website}
              addInlineSection={addInlineSection}
              position={position}
              data={data as IInline}
              updateSection={updateSection}
              config={config}
              fontColor={fontColor}
              setFontColor={setFontColor}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              prefixUrl={config?.storageConfig.url}
            />
          </>
        )
      default:
        return null
    }
  }

  const facebookPages = Object.keys(props.config.facebook_pages).map(function (key) {
    const intKey = parseInt(key)
    return props.config.facebook_pages[intKey]
  })

  const installedFacebookPages = facebookPages.filter(
    (facebookPage: IFacebookPage) => 'installed' === facebookPage.status,
  )

  return (
    <Container>
      {page.currentPage?.sections?.map((section, position) => {
        if ((position === 0 && theme.title !== 'Classic') || (position === 1 && theme.title === 'Classic')) {
          return (
            <Fragment key={position + section.name}>
              <SectionControl isPageManagement={true} isAdd={false} position={position} isDelete={false}>
                <ContainerGrid style={{ padding: 0, maxWidth: '100%' }} key={position + section.name}>
                  <MenuContainer>
                    {page.currentPage ? (
                      <Navigation
                        theme={theme}
                        currentPage={page.currentPage}
                        localPages={localPages}
                        setPages={setPages}
                        pages={page.list}
                        websiteId={website.id}
                        logoSrc={data?.logo && `${config?.storageConfig.url}/?id=${data.logo}`}
                        setLogoOpen={setLogoOpen}
                        isLogoVisible={data?.is_logo_visible}
                        isTitleVisible={data?.is_title_visible}
                        title={data?.title}
                      />
                    ) : null}
                    <LastItemsContainer>
                      {theme.title !== 'Classic' && isOnlineBooking && (
                        <BookButtonWrapper width={windowWidth} offSide={website.languages.length > 1}>
                          <BookButton
                            href={isOnlineBooking ? onlineBookingUrl : `tel:${phoneNumber}`}
                            target="_blank"
                            rel="noreferrer"
                            pagesLength={localPages.length > 5}
                            width={windowWidth}
                          >
                            {localPages.length > 5 && windowWidth < 1250 && windowWidth > 900 ? (
                              <BookButtonIcon
                                pages={localPages.length > 5}
                                window={windowWidth < 1250}
                                icon={'calendar-check'}
                              />
                            ) : (
                              <>
                                <BookButtonIcon icon={'calendar-check'} />
                                {localPages.length > 5 && windowWidth < 1400
                                  ? `${t('Book')}`
                                  : windowWidth < 900
                                  ? `${t('Book now')}`
                                  : `${t('Book now')}`}
                              </>
                            )}
                          </BookButton>
                        </BookButtonWrapper>
                      )}

                      {(isShopifyEnabled || isWooCommerceEnabled) && (
                        <ShopifyButtonWrapper width={windowWidth} offSide={website.languages.length > 1}>
                          <ShopifyButton
                            href={isShopifyEnabled ? `https://${shopifyUrl}` : `${wooCommerceUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            width={windowWidth}
                          >
                            {localPages.length > 5 && windowWidth < 1250 && windowWidth > 900 ? (
                              <ShopifyIcon
                                pages={localPages.length > 5}
                                window={windowWidth < 1250}
                                icon={'cart-shopping'}
                              />
                            ) : (
                              <>
                                <ShopifyIcon icon={'cart-shopping'} />
                                {t('Shop')}
                              </>
                            )}
                          </ShopifyButton>
                        </ShopifyButtonWrapper>
                      )}
                      <Languages
                        updateLanguage={(lan: string) => {
                          i18n.changeLanguage(lan)
                          updateCurrentLanguage(lan)
                        }}
                        languages={website.languages}
                        currentLanguage={website.currentLanguage}
                        theme={theme}
                      />
                    </LastItemsContainer>
                  </MenuContainer>
                </ContainerGrid>
              </SectionControl>
              {renderSection(section, config, position)}
            </Fragment>
          )
        } else {
          return <Fragment key={position + section.name}>{renderSection(section, config, position)}</Fragment>
        }
      })}
      {installedFacebookPages.length > 0 ? (
        <FacebookChat website={website} installedFacebookPages={installedFacebookPages} />
      ) : null}
    </Container>
  )
}

const mapState = (state: ApplicationState) => {
  const theme: ITheme | undefined = state.theme.find((oneTheme) => oneTheme.id === state.website.theme_id)

  if (!theme) {
    throw Error('Theme not found')
  }

  return {
    page: state.page,
    config: state.config,
    website: state.website,
    theme: theme,
  }
}

const mapDispatch = {
  updateCurrentLanguage,
  updatePageSection,
  addInlineSection,
  addFixedButtonSection,
  addConfigBySlug,
}

export default connect(mapState, mapDispatch)(Update)
