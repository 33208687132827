import React, { ReactNode, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'
import { ApplicationState, RootState } from 'store'
import { IInline, IPageItem } from 'store/page/types'

import {
  ActionButton,
  ActionEditor,
  ActionEditorWrapper,
  AddNew,
  Container,
  DotsIcon,
  EditActionWrapper,
  EditSectionContainer,
  EditSectionWrapper,
  HorizontalLine,
  NewSectionWrapper,
  PenIcon,
  Separator,
  Text,
} from './style'
import Modal from 'shared/Modal'
import ButtonModal from 'shared/ButtonModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NewSectionModal } from 'shared/SectionControl/NewSectionModal/NewSectionModal'
import { deleteSection } from 'store/page/actions'

type Props = {
  children: ReactNode
  isAdd?: boolean
  isDelete?: boolean
  isImage?: boolean
  position: number
  col?: number
  isPageManagement?: boolean
  currentPage: IPageItem | null
  data: IInline
  open: boolean
  setAddOpen: (addOpen: boolean) => void
  setCount: (count: number) => void
}

export type IItem = {
  label: string
  value: SectionNames
  icon?: string
}

export const list: IItem[] = [
  { label: 'Text', value: SectionNames.HTML, icon: 'bars' },
  { label: 'Icon and text', value: SectionNames.TEXTANDIMAGES, icon: 'icons' },
  { label: 'Gallery', value: SectionNames.GALLERY, icon: 'images' },
  { label: 'Pricing', value: SectionNames.PRICING, icon: 'tag' },
  { label: 'Photo', value: SectionNames.PHOTO, icon: 'image' },
  { label: 'Map', value: SectionNames.MAP, icon: 'map-location' },
  { label: 'Feedback', value: SectionNames.FEEDBACK, icon: 'comment' },
  { label: 'Contact', value: SectionNames.CONTACT, icon: 'address-card' },
  { label: 'Employees', value: SectionNames.EMPLOYEE, icon: 'users' },
  { label: 'Form', value: SectionNames.FORM, icon: 'file-lines' },
  { label: 'Button', value: SectionNames.BUTTON, icon: 'toggle-on' },
]

const SectionControl = ({
  children,
  isAdd = true,
  isDelete = true,
  position,
  currentPage,
  col,
  data,
  setAddOpen,
  setCount,
}: Props) => {
  const [selectedItem, setItem] = useState<IItem | null>(null)
  const [activeList, setActiveList] = useState<IItem[]>(list)
  const [isOpen, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isOnHover, setIsOnHover] = useState<boolean>(false)
  const [isActionEditorOpen, setIsActionEditorOpen] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const locale = useSelector<RootState, string>((state) => state.config.locale)

  useEffect(() => {
    const singleSections = [SectionNames.HEADER, SectionNames.FOOTER]

    if (currentPage && currentPage.sections?.length) {
      const sectionNames = currentPage.sections.map((section) => section.name)
      const newActiveList = list.reduce((state: IItem[], item: IItem) => {
        if (sectionNames.includes(item.value) && singleSections.includes(item.value)) {
          return state
        } else {
          state.push(item)
          return state
        }
      }, [])
      setActiveList(newActiveList)
    }
  }, [currentPage])

  const handleModalOpen = () => {
    setOpen(!isOpen)
  }

  const handleDeleteSection = () => {
    if (position >= 0) {
      dispatch(deleteSection(position, col))
    }
  }

  const handleEditSection = () => {
    setIsEditing(!isEditing)
  }

  const handleActionSection = () => {
    setIsActionEditorOpen(!isActionEditorOpen)
  }

  const addSection = () => {
    setCount(data.sections.length + 1)
    setAddOpen(true)
  }

  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'

  const isItOdd = currentPage?.sections.map((section, i) => i % 2)

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isEditing })
    }
    return child
  })

  return (
    <Container themeTitle={themeTitle} className="parent-container" isOdd={isItOdd}>
      {isDelete && (
        <>
          <EditSectionContainer>
            <EditSectionWrapper onClick={handleEditSection}>
              <PenIcon icon={'pen'} />
              <Text>{t('Edit section')}</Text>
              <Separator />
            </EditSectionWrapper>
            <EditActionWrapper onClick={handleActionSection}>
              <DotsIcon icon={'ellipsis-vertical'} />
            </EditActionWrapper>
          </EditSectionContainer>
          {isActionEditorOpen && (
            <ActionEditorWrapper>
              <ActionEditor>
                {data && data.sections.length <= 2 && (
                  <ActionButton onClick={addSection}>
                    <FontAwesomeIcon icon={'circle-plus'} />
                  </ActionButton>
                )}
                {isDelete && (
                  <ActionButton onClick={handleModalOpen}>
                    <FontAwesomeIcon icon={'trash'} />
                  </ActionButton>
                )}
              </ActionEditor>
            </ActionEditorWrapper>
          )}
        </>
      )}
      {childrenWithProps}
      <NewSectionWrapper>
        {isAdd && (
          <>
            <HorizontalLine hover={isOnHover} />
            <AddNew
              onClick={() => setIsModalOpen(!isModalOpen)}
              onMouseEnter={() => setIsOnHover(true)}
              onMouseLeave={() => setIsOnHover(false)}
              hover={isOnHover}
            >
              {t('Add section')}
            </AddNew>
          </>
        )}
      </NewSectionWrapper>
      {isModalOpen && (
        <NewSectionModal
          setIsModalOpen={setIsModalOpen}
          position={position}
          selectedItem={selectedItem}
          setItem={setItem}
          activeList={activeList}
        />
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={handleModalOpen}
        onDelete={() => handleDeleteSection()}
        title={t('page:delete', { lng: locale })}
      >
        {t('page:are_you_sure', { lng: locale })}
        <div style={{ textAlign: 'right' }}>
          <ButtonModal handleClick={handleModalOpen} className="dialog-close">
            {t('page:cancel', { lng: locale })}
          </ButtonModal>
          <ButtonModal className="dialog-delete" handleClick={handleDeleteSection}>
            {t('page:delete', { lng: locale })}
          </ButtonModal>
        </div>
      </Modal>
    </Container>
  )
}

const mapState = (state: ApplicationState) => ({
  currentPage: state.page.currentPage,
})

export default connect(mapState)(SectionControl)
