import React, { FunctionComponent, useEffect, useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import {
  CardContainer,
  Image,
  ImageContainer,
  Name,
  NameContainer,
  Line,
  CardWrapper,
  ImageIcon,
  ToolbarInputLabel,
  ToolbarInputContainer,
  ToolbarInput,
  TextContainer,
  ToolTip,
  ToolTipWrapper,
  SaveButton,
  InputName,
  InputDescription,
  Description,
  CropperWrapper,
  ActionsWrapper,
  ActionButton,
  ActionIcon,
  ActionText,
  ActionContainer,
  ImageWrapper,
  ImageEditButton,
  EditButtonIcon,
  ImageEditWrapper,
} from './style'
import {
  faFloppyDisk,
  faImage,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faCrop,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { addFile } from 'services/website-service'
import { errorToast, successToast } from 'services/toasts'
import { WEBSITE_EMPLOYEE } from '../../../constants'
import { IEmployeeCard } from '../../../../../shared-ui/src/types/sections'
import Spinner from 'shared/Spinner'
import Cropper, { Point } from 'react-easy-crop'
import { ICoordinates } from 'shared/Employee/RenderEmployee/ICoordinates'

export const Employee: FunctionComponent<IProps> = ({ data, updateSection, position, col }) => {
  const { t } = useTranslation()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [cardWrapperWidth, setCardWrapperWidth] = useState(0)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false)
  const [employeeName, setEmployeeName] = useState('')
  const [employeeDescription, setEmployeeDescription] = useState('')
  const [employeeImage, setEmployeeImage] = useState<string | undefined>('')
  const [currentToolTipId, setCurrentToolTipId] = useState<string | null>(null)
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<number>(1)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [onImageHover, setOnImageHover] = useState<boolean>(false)
  const [croppedCoordinates, setCroppedCoordinates] = useState<ICoordinates>({ x: 0, y: 0, scale: 1 })

  useEffect(() => {
    if (data) {
      setEmployeeName(data.name)
      setEmployeeDescription(data.description)
      setEmployeeImage(data.image)
    }

    if (data.imagePosition) {
      setCroppedCoordinates(data.imagePosition)
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    const cardWrapper = document.getElementById('cardWrapper')
    if (cardWrapper) {
      setCardWrapperWidth(cardWrapper.offsetWidth)
    }

    return () => {
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    }
  }, [])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  useEffect(() => {
    setCroppedCoordinates({ x: crop.x, y: crop.y, scale: zoom })
  }, [crop, zoom])

  useEffect(() => {
    if (selectedFile) {
      addFile(WEBSITE_EMPLOYEE, selectedFile)
        .then((response) => {
          successToast()
          const imageToString = response.data.data.id.toString()
          const employeeDataObject: IEmployeeCard = {
            image: imageToString,
            name: employeeName,
            description: employeeDescription,
          }
          updateSection('employee', employeeDataObject, position, col)
          setEmployeeImage(response.data.data.id.toString())
        })

        .catch(function (error) {
          // Too generic message because it display this message for all errors
          // but there is no other solution to catch a specific Status code

          if (error) {
            errorToast('Image is too big try again with a smaller one.')
          }
        })
    }
  }, [selectedFile, updateSection, col, position, employeeName, employeeImage, employeeDescription])

  const onCropSend = () => {
    const employeeDataObject: IEmployeeCard = {
      image: employeeImage,
      name: employeeName,
      description: employeeDescription,
      imagePosition: croppedCoordinates,
    }

    updateSection('employee', employeeDataObject, position, col)
  }

  useEffect(() => {
    if (isToolTipVisible) {
      setCurrentToolTipId(`tooltipId-${col}`)
    }
  }, [isToolTipVisible, col])

  const handleClickOutside = useCallback(
    (event: any) => {
      if (currentToolTipId) {
        const tooltip = document.getElementById(currentToolTipId)
        if (tooltip && !tooltip.contains(event.target)) {
          setCurrentToolTipId(null)
          setIsToolTipVisible(false)
        }
      }
    },
    [currentToolTipId, setIsToolTipVisible],
  )
  useEffect(() => {
    if (currentToolTipId) {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [currentToolTipId, handleClickOutside])

  if (!data) {
    return <Spinner isLoaded={true} />
  }

  const handleTextInput = () => {
    setIsToolTipVisible(true)
  }

  const onSave = () => {
    setIsToolTipVisible(false)
    const employeeDataObject: IEmployeeCard = {
      image: employeeImage,
      name: employeeName,
      description: employeeDescription,
    }

    updateSection('employee', employeeDataObject, position, col)
  }

  return (
    <div className="employee-card-wrapper">
      <CardWrapper id="cardWrapper">
        <CardContainer>
          <ImageContainer>
            {employeeImage ? (
              <>
                {!isEdit ? (
                  <ImageWrapper onMouseEnter={() => setOnImageHover(true)} onMouseLeave={() => setOnImageHover(false)}>
                    <Image
                      src={`${process.env.REACT_APP_FILES}${employeeImage}`}
                      incomingCoordinates={data.imagePosition}
                    />
                    {onImageHover && (
                      <ImageEditWrapper>
                        <ImageEditButton top={0} onClick={() => setIsEdit(!isEdit)}>
                          <EditButtonIcon icon={faCrop} />
                        </ImageEditButton>
                        <div>
                          <ImageEditButton top={'3.5em'} onClick={handleButtonClick}>
                            <EditButtonIcon icon={faUpload} />
                          </ImageEditButton>
                          <ToolbarInput type="file" ref={fileInputRef} onChange={handleFileChange} />
                        </div>
                      </ImageEditWrapper>
                    )}
                  </ImageWrapper>
                ) : (
                  <CropperWrapper>
                    <Cropper
                      image={`${process.env.REACT_APP_FILES}${employeeImage}`}
                      crop={crop}
                      minZoom={0.01}
                      zoom={zoom}
                      cropSize={{ width: 449, height: 195 }}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      objectFit={'horizontal-cover'}
                    />
                  </CropperWrapper>
                )}
              </>
            ) : (
              <ToolbarInputLabel>
                <ToolbarInputContainer windowWidth={windowWidth} isToolbarVisible={true}>
                  <ImageIcon icon={faImage} />
                  <ToolbarInput type="file" ref={fileInputRef} onChange={(event) => handleImageChange(event)} />
                </ToolbarInputContainer>
              </ToolbarInputLabel>
            )}
          </ImageContainer>
          {isEdit && (
            <ActionsWrapper>
              <ActionContainer>
                <ActionIcon color="#2d323d" icon={faMagnifyingGlassMinus} />
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={10}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(parseInt(e.target.value))
                  }}
                  className="zoom-range"
                />
                <ActionIcon color="#2d323d" icon={faMagnifyingGlassPlus} />
              </ActionContainer>
              <ActionButton onClick={() => setIsEdit(false)}>
                <ActionText>{t('Cancel')}</ActionText>
              </ActionButton>
              <ActionButton onClick={onCropSend}>
                <ActionContainer>
                  <ActionIcon color="#fff" icon={faFloppyDisk} />
                  <ActionText>{t('Save')}</ActionText>
                </ActionContainer>
              </ActionButton>
            </ActionsWrapper>
          )}

          <TextContainer onClick={handleTextInput}>
            <NameContainer>
              <Name>{t(`${employeeName}`)}</Name>
              <Line />
              <Description cardWrapperWidth={cardWrapperWidth}>{t(`${employeeDescription}`)}</Description>
            </NameContainer>
          </TextContainer>
          {isToolTipVisible && (
            <ToolTip isToolTipVisible={isToolTipVisible} id={`tooltipId-${col}`}>
              <ToolTipWrapper>
                <InputName
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  placeholder="Employee name"
                />
                <InputDescription
                  value={employeeDescription}
                  onChange={(e) => setEmployeeDescription(e.target.value)}
                  placeholder="Employee description"
                />
                <SaveButton onClick={onSave}>{t('Save')}</SaveButton>
              </ToolTipWrapper>
            </ToolTip>
          )}
        </CardContainer>
      </CardWrapper>
    </div>
  )
}
