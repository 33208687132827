import {
  IActivity,
  IActivityCategory,
  IApiActivity,
  IApiActivityCategory,
} from 'components/PricingCrud/components/ActivitySelect/interface/IActivity'
import {
  IApiMenuActivity,
  IMenuActivity,
} from 'components/PricingCrud/components/ActivitySelect/interface/IMenuActivity'
import {
  IApiMenuCategory,
  IMenuCategory,
} from 'components/PricingCrud/components/ActivitySelect/interface/IMenuCategory'

export const transformActivity = (apiActivity: IApiActivity): IActivity => {
  return {
    deposit: apiActivity.deposit ? apiActivity.deposit : undefined,
    description: apiActivity.description ? apiActivity.description : undefined,
    duration: apiActivity.duration,
    establishmentDetails: apiActivity.establishment_details,
    id: apiActivity.id,
    image: apiActivity.image ? apiActivity.image : undefined,
    isOnline: apiActivity.is_online,
    isPersonSelectable: apiActivity.person_choice,
    name: apiActivity.name,
    order: apiActivity.order,
    personsAmount: apiActivity.persons_amount ? apiActivity.persons_amount : undefined,
    price: apiActivity.price ? apiActivity.price : undefined,
    type: 'activity',
    waitTime: apiActivity.wait_time || 0,
    uuid: apiActivity.uuid,
  }
}

export const transformCategories = (apiActivityCategory: IApiActivityCategory): IActivityCategory => {
  return {
    ...apiActivityCategory,
    activities: apiActivityCategory.activities.map(transformActivity),
  }
}

export const transformMenuActivity = (apiActivity: IApiMenuActivity): IMenuActivity => {
  return {
    categoryId: apiActivity.parent_id,
    description: apiActivity.description ? apiActivity.description : undefined,
    id: apiActivity.id,
    image: apiActivity.image,
    name: apiActivity.name,
    order: apiActivity.order,
    personsAmount: apiActivity.persons_amount,
    type: 'menuActivity',
    duration: apiActivity.duration,
    waitTime: apiActivity.wait_time || 0,
    uuid: apiActivity.uuid,
  }
}

export const transformMenu = (categoriesArray: IApiMenuCategory[]): IMenuCategory[] => {
  return categoriesArray.map((apiCategory: IApiMenuCategory) => {
    const category: IMenuCategory = {
      id: apiCategory.id,
      image: apiCategory.image,
      name: apiCategory.name,
      order: apiCategory.order,
      parentId: apiCategory.parent_id,
      isMenu: apiCategory.is_menu,
      isOnline: apiCategory.is_online,
      description: apiCategory.description ? apiCategory.description : undefined,
      type: 'category',
    }
    if (apiCategory.categories) {
      category.categories = transformMenu(apiCategory.categories)
    }

    if (apiCategory.activities) {
      category.activities = apiCategory.activities.map((apiActivity) => transformMenuActivity(apiActivity))
    }

    return category
  })
}
