import {
  IConfig,
  ConfigAddAction,
  ADD_CONFIG,
  ADD_CONFIG_SLUG,
  ConfigAddBySlugAction,
  IConfigData,
  GET_MENU_CATEGORIES_SUCCESS,
  GET_EXTRA_ACTIVITIES_SUCCESS,
} from './types'
import { IMenuCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IMenuCategory'
import { IActivityCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IActivity'

export const addConfig = (config: IConfig): ConfigAddAction => {
  return {
    type: ADD_CONFIG,
    payload: config,
  }
}

export const addConfigBySlug = (name: string, section: IConfigData): ConfigAddBySlugAction => {
  return {
    type: ADD_CONFIG_SLUG,
    payload: { name, section },
  }
}

export const getMenuCategoriesSuccess = (name: string, menu: IMenuCategory[]) => {
  return {
    type: GET_MENU_CATEGORIES_SUCCESS,
    payload: { name, menu },
  }
}

export const getActivitiesSuccess = (name: string, activity: IActivityCategory[]) => {
  return {
    type: GET_EXTRA_ACTIVITIES_SUCCESS,
    payload: { name, activity },
  }
}
