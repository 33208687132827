import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { NavigationText, Wrapper, SubNavWrapper, SubnavArrow, ArrowSpan } from './style'
import { IPageItem } from '../../../../../shared-ui/src/types/page'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/page/actions'
import { useWindowDimensions } from '../../../helpers/hooks/useWindowDimensions/useWindowDimensions'

type Props = {
  item: IPageItem
  isActive: boolean
  open: boolean
  pages: IPageItem[]
  subPages?: IPageItem[]
}
const NavigationItem: FunctionComponent<Props> = ({ item, isActive, pages, subPages }: Props) => {
  const dispatch = useDispatch()
  const { windowWidth } = useWindowDimensions()
  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'
  const pagesLength = pages ? pages.length : 0

  return (
    <Wrapper
      pagesLength={pagesLength > 5}
      width={windowWidth}
      themeTitle={themeTitle}
      className={isActive ? 'active' : ''}
    >
      <Link to={`/update/${item.slug}`} onClick={() => dispatch(setCurrentPage(item))}>
        <NavigationText themeTitle={themeTitle}>{item.title}</NavigationText>
      </Link>

      {subPages && subPages.length > 0 ? (
        <>
          <SubnavArrow href="#">
            <ArrowSpan className="arrow">
              <span></span>
              <span></span>
            </ArrowSpan>
          </SubnavArrow>

          <SubNavWrapper className="subnav-wrapper">
            {subPages.map((subPage, key) => (
              <Link key={key} to={`/update/${subPage.slug}`} onClick={() => dispatch(setCurrentPage(subPage))}>
                <NavigationText themeTitle={themeTitle}>{subPage.title}</NavigationText>
              </Link>
            ))}
          </SubNavWrapper>
        </>
      ) : null}
    </Wrapper>
  )
}

export default NavigationItem
