import React, { FunctionComponent, useEffect, useState } from 'react'
import Spinner from 'shared/Spinner'
import App from './App'
import { getConfigAuth } from 'services/website-service'
import Cookie from 'js-cookie'
import { AxiosResponse } from 'axios'

interface IAuthReturn {
  establishment_id: number
  establishment_uuid: string
  locale: string
  organization_uuid: string
  token: string
}

const Bootstrap: FunctionComponent = () => {
  const [organisationUuid, setOrganisationUUid] = useState<string>()
  const [establishmentId, setEstablishmentId] = useState<string>()

  useEffect(() => {
    getConfigAuth().then(
      (response: AxiosResponse<IAuthReturn>) => {
        setEstablishmentId(response.data.establishment_id.toString())
        setOrganisationUUid(response.data.organization_uuid)
      },
      () => {
        const isEsthios: boolean = window.location.host.indexOf('esthios.') >= 0
        window.location.replace(
          (isEsthios ? process.env.REACT_APP_ESTHIOS_CLIENT_URL : process.env.REACT_APP_OPTIOS_CLIENT_URL) || '',
        )
      },
    )
  }, [])

  const matches = window.location.pathname.match(/\/auth\/([a-z0-9A-Z-]+)/)
  if (null !== matches && matches.length >= 2) {
    Cookie.set('access_token', matches[1])
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)

    return <Spinner isLoaded={true} />
  }

  if (!organisationUuid || !establishmentId) {
    return <Spinner isLoaded={true} />
  }

  return <App organisationUuid={organisationUuid} establishmentId={establishmentId} />
}

export default Bootstrap
