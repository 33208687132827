import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICoordinates } from 'shared/Employee/RenderEmployee/ICoordinates'

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  flex-wrap: wrap;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 10px;
  width: 456px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ImageIcon = styled(FontAwesomeIcon)`
  color: #b8b8b8;
  font-size: 2.5em;
  transform: translate(0px, 75px);
`
export const ImageContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 8px 8px 0 0;
  background: #dbdbdb;

  &:hover {
    cursor: pointer;
    background: #d6d4d4;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const ActionButton = styled.button`
  background-color: rgba(39, 46, 59, 1);
  border-radius: 4px;
  margin: 0 8px;
  padding: 8px;
  border: none;
  width: 100px;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

export const ActionIcon = styled(FontAwesomeIcon)<{ color: string }>`
  color: ${({ color }) => color};
  margin-left: 5px;
  padding: 6px;
`

export const ActionText = styled.span`
  color: #fff;
  margin-left: 5px;
  padding: 6px;
  font-weight: 700;
`

export const CropperWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 200px;
  position: relative;
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`

export const ToolTip = styled.span<{ isToolTipVisible: boolean }>`
  display: ${({ isToolTipVisible }) => (isToolTipVisible ? 'block' : 'none')};
  opacity: ${({ isToolTipVisible }) => (isToolTipVisible ? 1 : 0)};
  visibility: ${({ isToolTipVisible }) => (isToolTipVisible ? 'visible' : 'hidden')};
  width: 400px;
  height: 170px;
  background-color: #ededed;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 16px;
  position: absolute;
  z-index: 10;
  transform: translate(0%, 56%);
  margin-top: 8em;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ededed transparent;
  }
`

export const ToolTipWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const InputName = styled.input`
  margin-bottom: 6px;
`

export const InputDescription = styled.textarea`
  margin-bottom: 6px;
  height: 70px;
`

export const SaveButton = styled.button`
  background-color: #0090ff;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  vertical-align: middle;
  margin: 0 16px;
`

export const ToolbarInputLabel = styled.label`
  display: inline-block;
`

export const ToolbarInputContainer = styled.div<{
  isToolbarVisible: boolean
  windowWidth: number
}>`
  right: 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0.25em;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
  text-align: center;
  min-width: 200px;
  height: 200px;
  z-index: 5;
  &:hover {
    background: rgba(219, 219, 219, 0.2);
    cursor: pointer;
  }
`
export const ToolbarInput = styled.input`
  display: none;
`

export const ImageWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 200px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
`
export const Image = styled.img<{
  src: string
  incomingCoordinates: ICoordinates | { x: 0; y: 0; scale: 1 } | undefined
}>`
  width: 100%;
  height: auto;
  will-change: transform;
  transform: ${({ incomingCoordinates }) =>
    incomingCoordinates
      ? `translate(${incomingCoordinates.x}px, ${incomingCoordinates.y}px) scale(${incomingCoordinates.scale})`
      : 'none'};
`
export const ImageEditWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const ImageEditButton = styled.button<{ top: number | string }>`
  background-color: rgba(39, 46, 59, 1);
  color: #fff;
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
  border: none;
  position: absolute;
  top: ${({ top }) => top};
  right: 0;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const EditButtonIcon = styled(FontAwesomeIcon)`
  color: #fff;
  padding: 6px;
`
export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
  z-index: 10;
  width: 100%;
`

export const Name = styled.h3`
  font-size: 18px;
  padding: 0 8px;
  text-align: center;
  font-weight: 500;
  color: #212121;
`

export const Line = styled.hr`
  width: 80px;
  background-color: #dbdbdb;
  height: 1px;
  border: none;
`

export const Description = styled.p<{
  cardWrapperWidth: number
}>`
  line-height: 1.5;
  font-weight: 300 !important;
  text-align: center;
  word-break: keep-all;
  height: 150px;
  overflow: auto;
  padding: 16px;
  width: 100%;
`

export const Info = styled.textarea<{
  cardWrapperWidth: number
}>`
  font-size: 16px;
  text-align: center;
  color: #757575;
  padding: 16px;
  border: none;
  height: 140px;
`
