import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { stringToHsl } from '../../../../../../utils/stringToHsl'
import { IActivityCategory } from '../../interface/IActivity'
import { IFinalSelection } from '../../interface/IActivitySelect'
import { Tile } from '../Tile/Tile'

import { IProps } from './IProps'

export const ActivityCategorySelect: FunctionComponent<IProps> = ({
  activityList,
  activitySelectObject,
  setActivitySelectObject,
}) => {
  const [selectedPath, setSelectedPath] = useState<string>('')
  const { activeSelection } = activitySelectObject
  const { t } = useTranslation()

  const addSelectionId = (id: number) => {
    setActivitySelectObject({
      type: 'activity',
      activeSelection: [id],
      selectionDetails: null,
    })
    const selectedCategory = activityList.find((category) => category.id === id)

    if (selectedCategory) {
      setSelectedPath(`/activities/${selectedCategory.id}`)
    }
  }

  const addSelectionUuid = (uuidArray: IFinalSelection[]) => {
    setActivitySelectObject({
      type: 'selected',
      activeSelection,
      selectionDetails: uuidArray,
    })
    return
  }

  if (activeSelection && activeSelection.length === 0) {
    return (
      <>
        {activityList.map((extraActivity) => {
          return (
            <Tile
              key={extraActivity.id}
              tileTitle={t(`activities;;${extraActivity.name}`)}
              tileColor={stringToHsl(extraActivity.name, 50, 50)}
              onClick={() => addSelectionId(extraActivity.id)}
            />
          )
        })}
      </>
    )
  }

  const nextCategorySelection: IActivityCategory | undefined = activityList.find(
    (activity) => activity.id === activeSelection?.[0],
  )

  if (nextCategorySelection && nextCategorySelection.activities) {
    return (
      <>
        {nextCategorySelection.activities.map((activity, index) => (
          <Tile
            key={activity.id}
            tileTitle={t(`activities;;${activity.name}`)}
            tileColor={stringToHsl(activity.name, 50, 50)}
            onClick={() =>
              addSelectionUuid([
                {
                  uuid: activity.uuid,
                  selectionDetails: {
                    name: activity.name,
                    price: typeof activity.price === 'number' ? `${activity.price}` : '0',
                    id: activity.id.toString(),
                    path: selectedPath + '/' + activity.id,
                    category: nextCategorySelection.name,
                    position: index,
                  },
                },
              ])
            }
          />
        ))}
      </>
    )
  }

  return null
}
