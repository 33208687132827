import styled from '@emotion/styled'

export const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  top: 24%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  margin: 22px 0;

  &:focus {
    outline: none;
  }
`

export const StyledBurgerStripes = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 0.25rem;
  background: ${({ open }) => (open ? '#0D0C1D' : '#cdcdcd')};
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  :first-of-type {
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :nth-of-type(2) {
    opacity: ${({ open }) => (open ? '0' : '1')};
    transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
  }

  :nth-of-type(3) {
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`
export const StyledBurgerMenu = styled.div<{ open: boolean }>`
  z-index: 3;
  position: fixed;
  max-width: 305px;
  height: 100vh;
  overflow-y: auto;
  padding: 10em 1.5em 1em 4em;
  background: #ededed;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  text-align: left;
  top: 1px;
  left: -12px;
  transition: transform 0.3s ease-in-out;

  @media (min-width: 768px) {
    padding-left: 7.5em;
  }

  @media (max-width: 576px) {
    min-height: 586px;
  }

  @media (max-width: 360px) {
    min-height: 586px;
    width: 180px;
  }

  @media (max-width: 280px) {
    min-height: 586px;
    width: 120px;
  }

  a {
    font-size: 1.5rem;
    padding: 0;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1rem;
    }

    @media (min-width: 900px) {
      padding: 2rem 0;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }

  .subnav-wrapper a {
    padding: 1rem 0;
  }
`
