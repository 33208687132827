import React, { useState, useEffect, FunctionComponent, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import InputField from 'shared/InputField'
import Modal from 'shared/Modal'
import { Wrapper, Button, DeleteButton, IconBack, LinkToBack, Title } from './style'
import { createPage, editPage, deletePage, getPageByWebsite } from 'services/website-service'
import { successToast, errorToast } from 'services/toasts'
import { ApplicationState, RootState } from 'store'
import { IAdminPageItem, IPageItem } from 'store/page/types'
import { IConfig } from 'store/config/types'
import { IWebsite } from 'store/website/types'
import { addPages, setCurrentPage } from 'store/page/actions'
import ButtonModal from 'shared/ButtonModal'
import { useTranslation } from 'react-i18next'
import SelectField from 'shared/SelectField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  currentPage: IPageItem | null
  config: IConfig
  website: IWebsite
}

type FormValues = {
  slug: string
  title: string
  website_id: string
  locale: { [key: string]: string }
  nav_location: 'navigation' | 'footer'
  parent: string | null
}

const Page: FunctionComponent<Props & typeof mapDispatch> = (props: Props & typeof mapDispatch) => {
  const { currentPage, config, website, addPages } = props
  const [open, setOpen] = useState<boolean>(false)
  const [showParent, setShowParent] = useState<boolean>(false)
  const [showSubav, setShowSubav] = useState<boolean>(false)
  const { locale, pages } = useSelector<RootState, { locale: string; pages: IAdminPageItem[] }>((state) => ({
    locale: state.config.locale,
    pages: state.page.list,
  }))
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const isEdit = location.pathname !== '/page/new'
  const { register, handleSubmit, errors, setValue, reset, setError, clearErrors } = useForm<FormValues>()
  const canDeletePage = useMemo(
    () =>
      pages.filter(
        (onePage) => currentPage && currentPage.locale === onePage.locale && onePage.nav_location === 'navigation',
      ).length > 1,
    [pages, currentPage],
  )

  useEffect(() => {
    if (currentPage && location && location.pathname !== '/page/new') {
      setValue('slug', currentPage.slug)
      setValue('title', currentPage.title)
      setValue('locale.' + currentPage.locale, currentPage.locale)
      setValue('nav_location', currentPage.nav_location)
      if (currentPage.nav_location === 'navigation' && showSubav) setValue('parent', currentPage.parent)
      else setValue('parent', null)
    } else {
      website.languages.forEach((key) => {
        setValue('locale.' + key, true)
      })
      setValue('nav_location', 'navigation')
    }
  }, [currentPage, location, website.languages, setValue, showSubav])

  useEffect(() => {
    if (currentPage?.nav_location === 'navigation') {
      setShowParent(true)
    }

    if (currentPage?.parent) setShowSubav(true)
  }, [currentPage])

  useEffect(() => {
    if (currentPage?.nav_location === 'navigation') {
      setShowParent(true)
    }

    if (currentPage?.parent) setShowSubav(true)
  }, [currentPage])

  const handleChangeLocale = () => {
    clearErrors('locale')
  }

  const handleShowAsSubnav = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement
    const isChecked = target.checked

    setShowSubav(isChecked)
  }

  const onSave = (data: FormValues) => {
    let isValid = true
    let locales: string[] = []
    if (data.locale) {
      locales = Object.keys(data.locale).reduce((state: string[], language) => {
        if (data.locale[language]) {
          state.push(language)
        }
        return state
      }, []) as string[]

      if (!locales.length) {
        setError('locale.' + website.languages[website.languages.length - 1], {
          type: 'required',
          message: 'This is required! Select one or more locales',
        })

        isValid = false
      }
    }

    if (!isValid) {
      return
    }

    if (isEdit && currentPage && currentPage.id) {
      editPage({
        ...currentPage,
        slug: data.slug,
        title: data.title,
        nav_location: data.nav_location,
        parent: (data.parent && data.parent !== "") ? data.parent : null,
      }).then(
        () => {
          successToast()
          getPageByWebsite(website.id as string).then(
            ({ data }) => {
              addPages(data)
            },
            () => {
              console.log()
            },
          )
          history.push('/update/' + data.slug)
        },
        () => {
          errorToast()
        },
      )
    } else if (website && website.id) {
      locales.forEach((locale) => {
        const order = pages.reduce((currentOrder, page) => {
          return page.order >= currentOrder ? page.order + 1 : currentOrder
        }, 0)

        createPage({
          slug: data.slug,
          title: data.title,
          locale: locale,
          website_id: website.id as string,
          sections: [],
          order,
          nav_location: data.nav_location,
          parent: data.parent,
        }).then(
          () => {
            successToast()
            reset()
            history.push('/update')
            getPageByWebsite(website.id as string).then(
              ({ data }) => {
                addPages(data)
              },
              () => {
                console.log()
              },
            )
          },
          () => {
            errorToast()
          },
        )
      })
    }
  }

  const deleteCurrentPage = () => {
    if (currentPage && currentPage.id) {
      deletePage(currentPage.id).then(() => {
        successToast()
        getPageByWebsite(website.id as string).then(
          ({ data }) => {
            addPages(data)
            setCurrentPage(data[0])
            history.push('/update')
          },
          () => {
            console.log()
          },
        )
      })
    }
  }
  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setOpen(false)
  }

  type locationSelect = {
    label: string
    value: string
  }

  const navLocation = currentPage ? currentPage.nav_location : 'navigation'
  const parent = (currentPage && currentPage.parent && currentPage.parent !== "") ? currentPage.parent : null
  const mainPages = pages.filter(
    (page) =>
      page.nav_location === 'navigation' && page.title !== 'Home' && !page.parent && page.title !== currentPage?.title,
  )
  let locationsDropdown: locationSelect[] = []

  mainPages.forEach((page) => {
    if (page.id) {
      const newLocation = {
        label: page.title,
        value: page.id,
      }
      locationsDropdown = [...locationsDropdown, newLocation]
    }
  })

  return (
    <Wrapper>
      <LinkToBack to="/update">
        <>
          <IconBack>
            <FontAwesomeIcon icon={'arrow-left'} />
          </IconBack>
          {t('page:back', { lng: locale })}
        </>
      </LinkToBack>
      <Title>{t(isEdit ? 'page_form:update' : 'page_form:create', { lng: locale })}</Title>

      <form onSubmit={handleSubmit((data) => onSave(data))}>
        <InputField
          name="title"
          registerRef={register({
            required: 'This is required',
          })}
          label={t('page_form:title', { lng: locale })}
          error={errors?.title?.message}
          onChange={(e) => {
            const slug: string = e.target.value
              .toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^a-z0-9-]+/g, '')
            setValue('slug', slug)
          }}
        />
        <InputField
          name="slug"
          registerRef={register({
            required: 'This is required',
          })}
          label={t('page_form:slug', { lng: locale })}
          error={errors?.slug?.message}
        />
        <SelectField
          name="nav_location"
          defaultValue={{ label: t('page_form:nav_location:' + navLocation), value: navLocation }}
          registerRef={register({
            required: 'This is required',
          })}
          label={t('page_form:nav_location', { lng: locale })}
          error={errors?.nav_location?.message}
          list={[
            {
              label: t('page_form:nav_location:navigation'),
              value: 'navigation',
            },
            {
              label: t('page_form:nav_location:footer'),
              value: 'footer',
            },
          ]}
          handleSelect={(name, value) => {
            setValue(name, value)
            if (value === 'navigation') {
              setShowParent(true)
            } else {
              setShowSubav(false)
              setShowParent(false)
            }
          }}
        />
        {showParent && (
          <InputField
            name="is_subnav"
            type="checkbox"
            registerRef={register}
            onChange={(e) => handleShowAsSubnav(e)}
            label={t('page_form:show_as_subnav', { lng: locale })}
          />
        )}
        {showSubav && showParent && (
          <SelectField
            name="parent"
            defaultValue={parent ? { label: parent, value: parent } : undefined}
            registerRef={register()}
            label={t('page_form:parent', { lng: locale })}
            error={errors?.parent?.message}
            list={locationsDropdown}
            handleSelect={(name, value) => setValue(name, value)}
          />
        )}
        {!isEdit && (
          <>
            <p>{t('page_form:languages', { lng: locale })}</p>
            {website.languages.map((key: string) => (
              <InputField
                key={key}
                disabled={true}
                name={`locale.${key}`}
                error={errors?.locale?.[key]?.message}
                type="checkbox"
                registerRef={register}
                onChange={() => handleChangeLocale()}
                label={config.languages[key]}
              />
            ))}
          </>
        )}
        <Button type="submit">{t('page:submit', { lng: locale })}</Button>
        {isEdit && currentPage && currentPage.id && canDeletePage && (
          <DeleteButton
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              setOpen(true)
            }}
          >
            {t('page:delete', { lng: locale })}
          </DeleteButton>
        )}
      </form>
      <Modal isOpen={open} title="Delete" onRequestClose={onRequestClose}>
        {t('page:are_you_sure', { lng: locale })}
        <div style={{ textAlign: 'right' }}>
          <ButtonModal handleClick={onRequestClose} className="dialog-close">
            {t('page:cancel', { lng: locale })}
          </ButtonModal>
          <ButtonModal className="dialog-delete" handleClick={deleteCurrentPage}>
            {t('page:delete', { lng: locale })}
          </ButtonModal>
        </div>
      </Modal>
    </Wrapper>
  )
}

const mapState = (state: ApplicationState) => ({
  website: state.website,
  currentPage: state.page.currentPage,
  config: state.config,
})

const mapDispatch = {
  addPages,
  setCurrentPage,
}

export default connect(mapState, mapDispatch)(Page)
