// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faTwitter, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons'
import {
  faScissors,
  faUser,
  faUmbrella,
  faInfo,
  faExclamation,
  faGlobe,
  faCutlery,
  faCreditCard,
  faCoffee,
  faBell,
  faPencil,
  faCar,
  faBus,
  faBicycle,
  faThumbsUp,
  faStar,
  faHeart,
  faPlay,
  faLink,
  faWheelchair,
  faQuestion,
  faQuestionCircle,
  faPlus,
  faTimes,
  faCircleMinus,
  faDotCircle,
  faCirclePlus,
  faCircle,
  faTrash,
  faFloppyDisk,
  faArrowLeft,
  faPhone,
  faCircleNotch,
  faCartShopping,
  faCalendarCheck,
  faHeading,
  faBars,
  faIcons,
  faImages,
  faImage,
  faTag,
  faMapLocation,
  faComment,
  faAddressCard,
  faUsers,
  faFileLines,
  faObjectGroup,
  faToggleOn,
  faPen,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons'

export const setupIcons = (): void => {
  library.add(
    faInstagram,
    faTwitter,
    faFacebook,
    faTiktok,
    faScissors,
    faUser,
    faUmbrella,
    faInfo,
    faExclamation,
    faGlobe,
    faCutlery,
    faCreditCard,
    faCoffee,
    faBell,
    faPencil,
    faCar,
    faBus,
    faBicycle,
    faThumbsUp,
    faStar,
    faHeart,
    faPlay,
    faLink,
    faWheelchair,
    faQuestion,
    faQuestionCircle,
    faPlus,
    faTimes,
    faCircleMinus,
    faCirclePlus,
    faTrash,
    faFloppyDisk,
    faArrowLeft,
    faPhone,
    faDotCircle,
    faCircle,
    faCircleNotch,
    faCartShopping,
    faCalendarCheck,
    faHeading,
    faBars,
    faIcons,
    faImages,
    faImage,
    faTag,
    faMapLocation,
    faComment,
    faAddressCard,
    faUsers,
    faFileLines,
    faObjectGroup,
    faToggleOn,
    faPen,
    faEllipsisV
  )
}
