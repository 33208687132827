import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import { useScreenClass } from 'react-grid-system'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'

import Settings from 'components/Settings'
import SideMenu from 'components/SideMenu'
import { PageWrapper, MenuWrapper } from './style'
import WebsitePage from './pages/Website'
import UpdatePage from './pages/Update'
import Page from './pages/Page'
import WebsiteSettings from './pages/WebsiteSettings'
import { globalStyles } from './assets/styles/global'
import { themeClassic, themeLight, themeDark } from '@optios-frontend-website/shared-ui/src/theme'
import {
  getConfig,
  getTheme,
  getPageByWebsite,
  getWebsiteByOrganization,
  editPage,
  deployWebsite,
  deployEvent,
} from 'services/website-service'
import { successToast, errorToast } from 'services/toasts'
import { addDataConfig } from 'services/config'
import { ApplicationState } from 'store'
import { IConfig } from 'store/config/types'
import { IWebsite } from 'store/website/types'
import { addWebsite, updateCurrentLanguage } from 'store/website/actions'
import { addConfig, addConfigBySlug } from 'store/config/actions'
import { addPages, setCurrentPage, editPage as savePage } from 'store/page/actions'
import { addThemes } from 'store/theme/actions'
import { IPage } from 'store/page/types'
import { ITheme } from 'store/theme/types'
import { EventStatus } from './constants'
import Spinner from 'shared/Spinner'
import { useTranslation } from 'react-i18next'
import { setupIcons } from './setupIcons'

setupIcons()

type Props = {
  config: IConfig
  page: IPage
  website: IWebsite
  themes: ITheme[]
  organisationUuid: string
  establishmentId: string
}

type AllProps = Props & typeof mapDispatch

function App(props: AllProps): JSX.Element {
  const {
    page,
    website,
    themes,
    addConfig,
    addThemes,
    addWebsite,
    setCurrentPage,
    addPages,
    savePage,
    updateCurrentLanguage,
    addConfigBySlug,
    organisationUuid,
    establishmentId,
  } = props

  const [websiteTheme, setTheme] = useState<ITheme | undefined>()
  const [updateTheme, setUpdateTheme] = useState(themeLight)
  const [themeValue, setThemeValue] = useState<string>(localStorage.getItem('theme-ui-color-mode') || 'Classic')

  const [isLoaded, setLoad] = useState(true)
  const [isPublishing, setIsPublishing] = useState(false)
  const [eventId, setEventId] = useState('')
  const history = useHistory()
  const location = useLocation()
  const { i18n } = useTranslation()
  useEffect(() => {
    if (themes.length && website.id) {
      const theme = themes.find((item) => item.id === website.theme_id)
      if (theme) {
        setTheme(theme)
      }
    }
  }, [themes, website])

  useEffect(() => {
    if (eventId && website.id) {
      const interval = setInterval(() => {
        if (website.id && eventId) {
          deployEvent(website.id, eventId)
            .then(({ data }) => {
              if (EventStatus.STATUS_DONE === data.message.status) {
                setEventId('')
                setIsPublishing(false)
                clearInterval(interval)
                successToast('Publish completed')
              }
            })
            .catch(() => {
              clearInterval(interval)
              setEventId('')
              setIsPublishing(false)
              errorToast()
            })
        }
      }, 6000)
      return () => clearInterval(interval)
    }
  }, [eventId, website.id])
  const screenClass = useScreenClass()

  useEffect(() => {
    getTheme().then(({ data }) => {
      addThemes(data)
    })
  }, [addThemes])

  useEffect(() => {
    getWebsiteByOrganization(organisationUuid as string).then(
      ({ data: website }) => {
        const lang =
          navigator.languages[1] && website.languages.includes(navigator.languages[1])
            ? navigator.languages[1]
            : website.languages[0]
        updateCurrentLanguage(lang)
        i18n.changeLanguage(lang)
        website.establishmentId = establishmentId
        addWebsite(website)
      },
      () => {
        getConfig().then(({ data: config }) => {
          addConfig(config)
        })
        history.push('/')
        setLoad(false)
      },
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!website.id) {
      return
    }

    getConfig().then(({ data: config }) => {
      addConfig(config)
      addDataConfig(config.sections, addConfigBySlug)
    })

    getPageByWebsite(website.id as string).then(
      ({ data }) => {
        addPages(data)
        const currentPage = data.find((item) => item.locale === website.currentLanguage)
        if (currentPage || (data && data.length)) {
          const newCurrentPage = currentPage || data[0]
          setCurrentPage(newCurrentPage)
          if (location.pathname !== '/settings') {
            history.push('/update')
          }
        }
        setLoad(false)
      },
      () => {
        setLoad(false)
      },
    )
    // eslint-disable-next-line
  }, [website])

  const editCurrentPage = () => {
    if (page && page.currentPage) {
      const pageLists = page.list.find((page) => page)
      if (pageLists) {
        const bookingSection = pageLists.sections.find((section) => section.name === 'booking')
        if (bookingSection) {
          const bookingObject = bookingSection
          page.currentPage.sections.push(bookingObject)
        }
      }
      editPage(page.currentPage)
        .then(() => {
          if (null !== page.currentPage) {
            savePage(page.currentPage)
          }
          successToast()
        })
        .catch(() => {
          errorToast()
        })
    }
  }

  const publishWebsite = () => {
    if (website.id) {
      setIsPublishing(true)
      deployWebsite(website.id)
        .then(({ data }) => {
          setEventId(data.id)
        })
        .catch(() => {
          errorToast()
        })
    }
  }

  useEffect(() => {
    switch (themeValue) {
      case 'Light':
        setUpdateTheme(themeLight)
        localStorage.setItem('theme-ui-color-mode', 'Light')
        break
      case 'Dark':
        setUpdateTheme(themeDark)
        localStorage.setItem('theme-ui-color-mode', 'Dark')
        break
      default:
        setUpdateTheme(themeClassic)
        localStorage.setItem('theme-ui-color-mode', 'Classic')
        break
    }
  }, [themeValue])

  if (!websiteTheme) {
    return <Spinner isLoaded={false} />
  }

  return (
    <div>
      <ThemeProvider theme={updateTheme}>
        <Global styles={globalStyles} />
        <MenuWrapper>
          <SideMenu />
        </MenuWrapper>
        <Settings
          website={website}
          disabledPublish={isPublishing}
          publish={publishWebsite}
          editCurrentPage={editCurrentPage}
        />
        <PageWrapper className={screenClass}>
          <Switch>
            <Route exact={true} path="/">
              <WebsitePage />
            </Route>
            <Route exact={true} path="/settings">
              <WebsitePage />
            </Route>
            <Route path={['/page', '/page/new']}>
              <Page />
            </Route>
            <Route path="/website-settings">
              <WebsiteSettings setThemeValue={setThemeValue} />
            </Route>
            <Route path={['/update', '/update/:slug']}>
              <UpdatePage />
            </Route>
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
          />
        </PageWrapper>
      </ThemeProvider>
      <Spinner
        isLoaded={isLoaded || isPublishing}
        text={isPublishing ? 'This can take up to 10 minutes, please wait.' : undefined}
      />
    </div>
  )
}

const mapState = (state: ApplicationState) => ({
  config: state.config,
  page: state.page,
  website: state.website,
  themes: state.theme,
})

const mapDispatch = {
  setCurrentPage,
  addPages,
  addWebsite,
  addThemes,
  addConfig,
  updateCurrentLanguage,
  addConfigBySlug,
  savePage,
}

export default connect(mapState, mapDispatch)(App)
