import styled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MediaBlock = styled.div`
  padding: 0.5em;
  background: #f7f7f7;
  margin-bottom: 0.5em;
`

export const Button = styled.button`
  background-color: rgba(39, 46, 59, 1);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 0;
  border-radius: 5px;
  width: auto;
  outline: none;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const SaveIcon = styled(FontAwesomeIcon)`
  color: white;
  margin-left: 5px;
  padding: 6px;
  vertical-align: middle;
`

export const AddIcon = styled(FontAwesomeIcon)`
  color: white;
  margin-left: 5px;
  padding: 6px;
  vertical-align: middle;
`

export const Text = styled.span`
  color: white;
  padding: 6px;
  font-weight: 700;
  vertical-align: middle;
`

export const FormContainer = styled.div`
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  margin: 0.5em 0;
  padding: 0.5em;
`
export const CollapseForm = styled.div`
  height: 0;
  transition: height 0.25s ease-out;
  opacity: 0;
  &.active {
    margin-top: 0.5em;
    height: auto;
    transition: height 0.25s ease-in;
    opacity: 1;
  }
`
export const SocialContent = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 2;
`

export const SocialContentName = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #232934;
  font-size: 0.8em;
`

export const SocialContentLink = styled.div`
  color: rgba(0, 0, 0, 0.5);
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.5em;
`

export const DeleteSocial = styled.span`
  cursor: pointer;
  color: red;
`

export const Title = styled.div`
  color: rgba(0, 0, 0, 0.5);
`

export const Footer = styled.footer`
  padding: 24px;
  color: ${({ theme }) => theme.colors.feedbackParagraph};
  text-align: center;
  font-size: ${({ theme }) => theme.sizes.xsmall};
  margin-top: 0;
`
export const List = styled.ul`
  margin: 1.5em 0 2em 0;
  list-style-type: none;
`

export const Item = styled.li`
  border: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  line-height: 1.2em;
  border-right: solid 1px ${(props) => props.theme.colors.border};
  padding: 0 1em 0 0;
  margin: 0 1em 0 0;
  a {
    color: inherit;
  }
  &:last-child {
    border-right: none;
  }
`
export const Socials = styled.ul`
  text-align: center;
  margin: 0;
  li {
    display: inline-block;
    line-height: 1em;
    padding-left: 1em;
    &:first-of-type {
      padding-left: 0em;
    }
    a {
      text-decoration: none;
      color: #999;
      font-size: ${({ theme }) => theme.sizes.xlarge};
      &:hover {
        color: #474747;
      }
    }
  }
`

export const SelectFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SelectFieldTextWrapper = styled.div`
  flex: 0 0 30%;
`
