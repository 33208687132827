import React, { useState, FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WEBSITE_BANNER, Regexp } from '../../../../constants'
import ImageList from '../ImageList'
import Dropzone from 'shared/Dropzone'
import Modal from 'shared/Modal'
import InputField from 'shared/InputField'
import Button from 'shared/ButtonModal'
import { IHeader, PageSectionTypes } from 'store/page/types'
import { useSelector } from 'react-redux'
import { ApplicationState, RootState } from 'store/index'
import { getConfigAuth, getGallery } from 'services/website-service'
import {
  ErrorMessageIcon,
  ErrorMessageText,
  ErrorMessageWrapper,
  InfoMessage,
} from 'components/Header/components/ModalHeader/style'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

type FileData = {
  name: string | null
  data: string | ArrayBuffer | null
}

type Props = {
  addWebsiteFile: (assetType: typeof WEBSITE_BANNER, file: File) => void
  bannerFiles: { id: number; mime: string }[]
  prefixUrl: string
  open: boolean
  currentFile: string
  data: IHeader
  setIsOpen: (open: boolean) => void
  onSubmit: (data: IHeader) => void
  position: number
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  errorMessage: string
}

type FormValues = {
  phone: string
  is_online_booking: boolean
}

const ModalHeader: FC<Props> = (props: Props) => {
  const { phone, is_online_booking, background_image, is_video = false, is_online_shop, online_shop_url } = props.data
  const { register, handleSubmit, reset, errors, setError } = useForm<FormValues>({
    defaultValues: { phone, is_online_booking },
  })

  const [fileData, setFile] = useState<FileData>({ data: null, name: null })
  const [backgroundImageId, setBackgroundImageId] = useState<string>(background_image)
  const [isBackgroundVideo, setIsBackgroundVideo] = useState<boolean>(is_video)
  const [isOnlineShop, setIsOnlineShop] = useState<boolean>(is_online_shop)
  const [webShopUrl, setWebShopUrl] = useState<string>(online_shop_url)
  const { locale, configuration, storage } = useSelector((state: ApplicationState) => ({
    locale: state.config.locale,
    configuration: state.config,
    storage: state.config.storageConfig,
  }))
  const { t } = useTranslation()

  useEffect(() => {
    setBackgroundImageId(props.data.background_image)
  }, [props.data.background_image])

  useEffect(() => {
    getConfigAuth().then(({ data }) => {})
  }, [])

  useEffect(() => {
    setIsOnlineShop(configuration.is_shopify_enabled ? true : configuration.is_woo_commerce_enabled)
    setWebShopUrl(storage.shopify_url ? storage.shopify_url : storage.woocommerce_url ? storage.woocommerce_url : '')
  }, [configuration, storage])

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const data = reader.result
        setFile({ data, name: file.name })
        props.addWebsiteFile(WEBSITE_BANNER, file)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const establishmentId = useSelector<RootState, string>((state) => state.website.establishmentId)

  return (
    <Modal
      title="Header"
      className="dialog-size-large"
      isOpen={props.open}
      onRequestClose={() => props.setIsOpen(false)}
    >
      <div>
        <InfoMessage>
          {t(`You can download free images or videos from stock photo sites like Pexels and Unsplash.`, { lng: locale })}
        </InfoMessage>
        <ImageList
          activeId={backgroundImageId}
          onImageSelected={(imageId, isVideo) => {
            setBackgroundImageId(imageId)
            setIsBackgroundVideo(isVideo)
          }}
          updateSection={props.updateSection}
          position={props.position}
          url={props.prefixUrl}
          bannerFiles={props.bannerFiles}
        />
        <Dropzone onDrop={onDrop} fileName={fileData.name} locale={locale} />
      </div>
      <form
        style={{ marginTop: '1em' }}
        onSubmit={handleSubmit((formData) => {
          getGallery(WEBSITE_BANNER)
          reset(formData)
          if (!formData.is_online_booking && !formData.phone.length) {
            setError('phone', {
              type: 'required',
              message: 'Complete one of the booking methods',
            })
            return
          }
          props.onSubmit({
            ...props.data,
            ...formData,
            background_image: backgroundImageId,
            is_video: isBackgroundVideo,
            is_online_shop: isOnlineShop,
            online_shop_url: webShopUrl,
            establishment_id: establishmentId,
          } as IHeader)
        })}
      >
        <InputField
          label={t('header:phone', { lng: locale })}
          name="phone"
          registerRef={register({
            pattern: { value: new RegExp(Regexp.PHONE), message: 'Enter valid phone number' },
          })}
          error={errors.phone?.message}
        />
        <InputField
          label={t('header:is_online_booking', { lng: locale })}
          type="checkbox"
          name="is_online_booking"
          registerRef={register}
        />
        {props.errorMessage && (
          <>
            <ErrorMessageWrapper>
              <ErrorMessageIcon icon={faTriangleExclamation} />
              <ErrorMessageText>{props.errorMessage}</ErrorMessageText>
            </ErrorMessageWrapper>
          </>
        )}

        <div style={{ textAlign: 'right' }}>
          <Button
            handleClick={() => {
              props.setIsOpen(false)
              getGallery(WEBSITE_BANNER)
            }}
            className="dialog-close"
          >
            {t('page:cancel', { lng: locale })}
          </Button>
          <Button type="submit"> {t('page:save', { lng: locale })}</Button>
        </div>
      </form>
    </Modal>
  )
}

export default ModalHeader
