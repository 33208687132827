import { PageSectionTypes } from '@optios-frontend-website/shared-ui/src/types/sections'
import { IPageItem, IPage } from '@optios-frontend-website/shared-ui/src/types/page'
import { SectionNames } from '../../constants'
export * from '@optios-frontend-website/shared-ui/src/types/sections'
export * from '@optios-frontend-website/shared-ui/src/types/page'

export const ADD_PAGES = '@@page/PAGES_ADDED'
export const CREATE_PAGE = '@@page/PAGE_CREATED'
export const EDIT_PAGE = '@@page/PAGE_EDITED'
export const SORT_PAGES = '@@page/PAGES_SORTED'
export const SET_CURRENT_PAGE = '@@page/SET_CURRENT_PAGE'
export const EDIT_PAGE_SECTION = '@@page/EDIT_SECTION'
export const ADD_NEW_SECTION = '@@page/ADD_NEW_SECTION'
export const DELETE_SECTION = '@@page/DELETE_SECTION'
export const ADD_INLINE_SECTION = '@@page/ADD_INLINE_SECTION'
export const ADD_FIXED_BUTTON_SECTION = '@@page/ADD_FIXED_BUTTON_SECTION'

export interface IAdminPageItem extends IPageItem {
  isUpdated: boolean
}

export interface IAdminPage extends IPage {
  list: IAdminPageItem[]
  currentPage: IAdminPageItem | null
}

export interface IPageAddAction {
  type: typeof ADD_PAGES
  payload: IPageItem[]
}

export interface IPageSortAction {
  type: typeof SORT_PAGES
  payload: IPageItem[]
}

export interface IPageCreateAction {
  type: typeof CREATE_PAGE
  payload: IPageItem
}

export interface IPageEditAction {
  type: typeof EDIT_PAGE
  payload: IPageItem
}

export interface ISetCurrentPageAction {
  type: typeof SET_CURRENT_PAGE
  payload: IPageItem | null
}

export interface IAddNewSectionAction {
  type: typeof ADD_NEW_SECTION
  payload: { position: number; name: SectionNames; data: PageSectionTypes | undefined }
}

export interface IAddInlineSectionAction {
  type: typeof ADD_INLINE_SECTION
  payload: { position: number; name: SectionNames }
}

export interface IAddFixedButtonSectionAction {
  type: typeof ADD_FIXED_BUTTON_SECTION
  payload: { position: number; name: SectionNames }
}

export interface IDeleteSectionAction {
  type: typeof DELETE_SECTION
  payload: { position: number; col?: number }
}

export interface IEditPageSectionAction {
  type: typeof EDIT_PAGE_SECTION
  payload: { name: string; section: PageSectionTypes; position: number; col?: number }
}

export type PageActionTypes =
  | IPageAddAction
  | IPageCreateAction
  | IPageEditAction
  | ISetCurrentPageAction
  | IAddNewSectionAction
  | IDeleteSectionAction
  | IEditPageSectionAction
  | IAddInlineSectionAction
  | IAddFixedButtonSectionAction
  | IPageSortAction

export interface IActivityDetails {
  category: string
  price: string
  id: string
  path: string | null
  position: number
}

interface IActivityData {
  data: IActivityDetails[]
  name: string
  position: number
}

export interface IActivityCategories {
  [key: string]: IActivityData
}
