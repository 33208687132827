import React, { FunctionComponent, useState, useMemo } from 'react'
import { Container } from 'react-grid-system'

import { RenderPricingList } from '@optios-frontend-website/shared-ui/src/components/RenderPricingList/RenderPricingList'
import Modal from 'shared/Modal'
import SectionControl from 'shared/SectionControl'
import { IActivityCategories } from 'store/page/types'
import Button from 'shared/ButtonModal'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/index'
import {
  getActivitySelectCall,
  getMenuCategoriesCall,
} from 'services/establishment-activity-call/establishment-activity-call'
import { getActivitiesSuccess, getMenuCategoriesSuccess } from 'store/config/actions'
import Spinner from 'shared/Spinner'
import { PricingList } from 'components/PricingCrud/components/PricingList/PricingList'

export const PricingCrud: FunctionComponent<IProps> = ({
  activityCategories,
  isOnlineBooking,
  phoneNumber,
  position,
  updateSection,
}) => {
  const activityCheck = Object.entries(activityCategories).length !== 0
  const [modalIsOpen, setIsOpen] = useState(false)
  const [services, setServices] = useState<IActivityCategories>(activityCheck ? activityCategories : {})
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const menuData = useSelector((state: RootState) => state.config.data.menu)
  const activityData = useSelector((state: RootState) => state.config.data.activities)
  const establishmentId = useSelector((state: RootState) => state.config.data.booking?.id)

  useMemo(() => {
    if (establishmentId) {
      getActivitySelectCall(establishmentId).then((activityArray) =>
        dispatch(getActivitiesSuccess('activities', activityArray)),
      )
      getMenuCategoriesCall(establishmentId).then((menuArray) => dispatch(getMenuCategoriesSuccess('menu', menuArray)))
    }
  }, [establishmentId, dispatch])

  useMemo(() => {
    if (activityCheck) {
      setServices(activityCategories)
    }
  }, [activityCategories, activityCheck])

  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setServices({})
    setIsOpen(false)
  }

  const onSave = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    updateSection('pricing', services, position)
    setIsOpen(false)
  }

  const open = () => {
    setServices(activityCategories)
    setIsOpen(true)
  }

  if (menuData === null || activityData === null) {
    return <Spinner isLoaded={false} />
  }

  if (!activityCategories) {
    return <Spinner isLoaded={false} />
  }

  return (
    <>
      <SectionControl position={position}>
        <RenderPricingList
          isOnlineBooking={isOnlineBooking}
          phoneNumber={phoneNumber}
          pricing={activityCategories}
          handleClick={open}
        />
      </SectionControl>
      <Modal
        isOpen={modalIsOpen}
        onSubmit={onSave}
        onRequestClose={onRequestClose}
        title="Pricing part"
        minWidth={'50em'}
      >
        <Container>
          <PricingList services={services} setServices={setServices} activityList={activityData} menuList={menuData} />
          <div style={{ textAlign: 'right' }}>
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel')}
            </Button>
            <Button type="submit" handleClick={onSave}>
              {t('page:save')}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  )
}
