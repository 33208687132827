import React, { FunctionComponent } from 'react'
import { Col } from 'react-grid-system'
import { IProps } from './IProps'
import InputFiled from 'shared/InputField'

export const ActivityField: FunctionComponent<IProps> = ({
  data,
  handleChange,
  itemIndex,
  category,
  categoryPosition,
}) => {
  return (
    <>
      <Col md={5} sm={10}>
        <InputFiled
          label="Service"
          name="category"
          value={data.category}
          onChange={(e) => handleChange(e, itemIndex, 'category', category, categoryPosition)}
        />
      </Col>
      <Col md={5} sm={10}>
        <InputFiled
          label="Price"
          name="price"
          value={data.price}
          onChange={(e) => handleChange(e, itemIndex, 'price', category, categoryPosition)}
        />
      </Col>
    </>
  )
}
