import React, { FunctionComponent, useMemo, useState } from 'react'

import { Tile } from '../Tile/Tile'

import { IProps } from './IProps'
import { getNextCategories } from './utils/getNextCategories'
import { IFinalSelection } from 'components/PricingCrud/components/ActivitySelect/interface/IActivitySelect'
import { stringToHsl } from '../../../../../../utils/stringToHsl'

export const MenuSelect: FunctionComponent<IProps> = ({ menuList, setActivitySelectObject, activitySelectObject }) => {
  const [selectedPath, setSelectedPath] = useState<string>('')
  const { activeSelection } = activitySelectObject

  const selectionCheck = activeSelection ? activeSelection : []
  const nextCategorySelection = useMemo(() => {
    return getNextCategories(activeSelection || [], menuList)
  }, [activeSelection, menuList])

  const addSelectionId = (newItem: number | IFinalSelection[], selectedId: number) => {
    if (typeof newItem === 'number') {
      const parentCategory = menuList.find((item) => (item.categories ? item.id === newItem : false))

      if (parentCategory && parentCategory.categories) {
        const selectedItem = parentCategory.categories.find((item) => item.id === selectedId)
        const newSelectedPath = selectedItem ? '/menu/' + newItem + '/' + selectedId : '/menu/' + newItem

        setSelectedPath(newSelectedPath)
      } else {
        setSelectedPath('/menu/' + newItem)
      }

      setActivitySelectObject({
        type: 'menu',
        activeSelection: Array.isArray(newItem)
          ? [...selectionCheck, ...newItem.map((item) => item.uuid)]
          : [...selectionCheck, newItem],
        selectionDetails: null,
      })
      return
    }

    if (Array.isArray(newItem)) {
      newItem.forEach((item) => {
        item.selectionDetails.path = selectedPath + '/' + selectedId
      })
    }

    setActivitySelectObject({
      type: 'selected',
      activeSelection,
      selectionDetails: newItem,
    })
  }

  return (
    <>
      {nextCategorySelection.map((item) => {
        const clickedTileInfo: number | IFinalSelection[] =
          typeof item.activities !== 'undefined'
            ? item.activities.map((activity, index) => ({
              uuid: activity.uuid,
              selectionDetails: {
                name: activity.name,
                price: typeof activity.price === 'number' ? `${activity.price}` : '0',
                id: activity.id.toString(),
                path: selectedPath,
                category: item.name,
                position: index
              },
            }))
            : item.id
        return (
          <Tile
            key={item.id}
            tileColor={stringToHsl(item.name, 50, 50)}
            tileTitle={item.name}
            onClick={() => addSelectionId(clickedTileInfo, item.id)}
          />
        )
      })}
    </>
  )
}
