import {
  IActivityCategory,
  IApiActivityCategory,
} from 'components/PricingCrud/components/ActivitySelect/interface/IActivity'
import { AxiosResponse } from 'axios'
import {
  IApiMenuCategory,
  IMenuCategory,
} from 'components/PricingCrud/components/ActivitySelect/interface/IMenuCategory'
import { transformCategories, transformMenu } from 'services/establishment-activity-call/apiActivityTransformer'
import axiosClient from 'services/axiosClient'

export const getActivitySelectCall = (establishmentId: string): Promise<IActivityCategory[]> => {
  return axiosClient
    .get(`anonymous/calendar/activity-tree?establishment_id=${establishmentId}`)
    .then((response: AxiosResponse<IApiActivityCategory[]>) => {
      return response.data.map((category: IApiActivityCategory) => transformCategories(category))
    })
}
export const getMenuCategoriesCall = (establishmentId: string): Promise<IMenuCategory[]> => {
  return axiosClient
    .get(`anonymous/activity-menu?establishment_id=${establishmentId}`)
    .then((response: AxiosResponse<IApiMenuCategory[]>) => transformMenu(response.data))
}
