import styled from '@optios-frontend-website/shared-ui/src/theme'

type StyledComponentProps = {
  hideComponent: boolean | undefined
}

type InputProps = {
  $type?: string
}

export const Label = styled.label`
  display: ${(props: StyledComponentProps) => (props.hideComponent ? 'none' : 'flex')};
  cursor: pointer;
  margin: 0 0 0.7em;
  min-height: 1.6em;
  position: relative;
  color: ${({ theme }) => theme.colors.text};
`

export const InputBlock = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: ${(props: InputProps) => (props.$type === 'checkbox' ? 'flex-start' : 'normal')};
  justify-content: ${(props: InputProps) => (props.$type === 'checkbox' ? 'center' : 'normal')};
`

export const Input = styled.input`
  font: inherit;
  // flex: 1 0 0;
  min-width: 0;
  width: 100%;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  margin: 0;
  padding: 0.2em 0;
  appearance: none;
  background: none;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 0;
  line-height: 1.4;
  &:focus {
    outline: none;
    box-shadow: 0 1px 0 #2164db;
  }
`
export const TextArea = styled.textarea`
  font: inherit;
  width: 100%;
  margin: 0.5em 0 0;
  min-width: 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 0;
  line-height: 1.4;
  height: 10em;
  overflow: auto;
  &:focus {
    outline: none;
    box-shadow: 0 1px 0 #2164db;
  }
`

export const Checkbox = styled.input`
  display: ${(props: StyledComponentProps) => (props.hideComponent ? 'none' : 'block')};
`

export const Text = styled.div`
  display: ${(props: StyledComponentProps) => (props.hideComponent ? 'none' : 'block')};
  padding: 0.2em 0.5em 0.2em 0;
  flex: 0 0 30%;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.text};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Error = styled.span`
  color: ${(props) => props.theme.colors.danger};
`
