import React, { Fragment, FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  Cost,
  Title,
  PricingContainer,
  CategoryText,
  CategoryHorizontalLine,
  CategoryBookNowButton,
  PricingContainerWrapper,
  PricingContentWrapper,
} from './style'

import { Api } from '../../constants'
import { IProps } from './IProps'
import { useSelector } from 'react-redux'
import { RootState } from '@optios-frontend-website/website-admin/src/store'

export const RenderPricingList: FunctionComponent<IProps> = ({
  isOnlineBooking,
  phoneNumber,
  handleClick,
  pricing,
}) => {
  const { t } = useTranslation()
  const onlineBookingUrl = Api.BOOKING_URL
  const establishmentId = useSelector<RootState, string>((state) => state.website.establishmentId)

  return (
    <PricingContainer onClick={handleClick}>
      <PricingContainerWrapper>
        <div>
          <Title>{t('page:pricing')}</Title>
        </div>
        {Object.entries(pricing).map((category, index) => {
          const categoryName = category[1].name
          const activityDetails = category[1].data

          if (!activityDetails) return

          return (
            <Fragment key={index}>
              <h5>{categoryName}</h5>
              {activityDetails.map((activityDetail, activityIndex) => (
                <Fragment key={activityIndex}>
                  <Row>
                    <Col>
                      <CategoryText>{activityDetail.category}</CategoryText>
                    </Col>
                    <PricingContentWrapper>
                      <Cost>&euro;&nbsp;{activityDetail.price}</Cost>
                    </PricingContentWrapper>
                    <Col xxl={1} xl={1} lg={1}>
                      <CategoryBookNowButton
                        href={
                          isOnlineBooking
                            ? onlineBookingUrl + +establishmentId + activityDetail.path
                            : `tel:${phoneNumber}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('Book')}
                      </CategoryBookNowButton>
                    </Col>
                  </Row>
                </Fragment>
              ))}
              <CategoryHorizontalLine />
            </Fragment>
          )
        })}
      </PricingContainerWrapper>
    </PricingContainer>
  )
}
